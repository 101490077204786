import cocaCola from '../../images/brands/coca-cola-logo.png'
import fieldEntertainment from '../../images/brands/field-entertainment-logo.png'
import ironman from '../../images/brands/ironman-logo.png'
import mastercard from '../../images/brands/mastercard-logo.png'
import shell from '../../images/brands/shell-logo.png'
import umoMobility from '../../images/brands/umo-mobility-logo.png'
import visa from '../../images/brands/visa-logo.png'


const images = [
    cocaCola,
    fieldEntertainment,
    ironman,
    mastercard,
    shell,
    umoMobility,
    visa
]

export default images
