import React from 'react'
import ReactDOM from 'react-dom/client'
import 'tailwindcss/tailwind.css'
import './styles/globals.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import UnderConstruction from 'pages/UnderConstruction/UnderConstruction'
import Home from 'pages/Home/Home'
import Faq from 'pages/Faq/Faq'
import SolutionDetail from 'pages/SolutionDetail/SolutionDetail'
import ContactUs from 'pages/ContactUs/ContactUs'

// import reportWebVitals from './reportWebVitals'
// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='case_study/:id' element={<UnderConstruction />} />
        <Route path='solutions/:id' element={<SolutionDetail />} />
        <Route path='faq' element={<Faq />} />
        <Route path='contact_us' element={<ContactUs />} />
        <Route path='login' element={<UnderConstruction />} />
        <Route path='about_us' element={<UnderConstruction />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
