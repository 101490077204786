import { FC, useState } from 'react'
import { motion } from "framer-motion"
import { Link } from "react-router-dom"
import DesktopDropdownItemProps from './DesktopDropdownItem.interface'

const DesktopDropdownItem: FC<DesktopDropdownItemProps> = ({title, subtitle, link, handleClick}) => {
  const [color, setColor] = useState('white')
  const [solutionRotate, setSolutionRotate] = useState(0)

  const handleEnter = () => {
      setColor('grey')
      setSolutionRotate(45)
  }

  const handleExit = () => {
      setColor('white')
      setSolutionRotate(0)
  }

  return (
      <Link 
          to={link}
          className='flex px-5 py-4'
          onMouseOver={() => handleEnter()}
          onMouseOut={() => handleExit()}
          onClick={handleClick}
      >
          <div className='mr-5'>
              <span className={`text-${color} typo-p-large`}><b>{title}</b></span>
              <p className={`text-${color} typo-p-medium`}>{subtitle}</p>
              
          </div>
          <motion.svg 
            xmlns="http://www.w3.org/2000/svg"
            // fill='brightBlue'
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke={`${color}`}
            className='w-8 h-8'
            animate={{rotate: solutionRotate}}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
          </motion.svg>
      </Link>
  )
}

export default DesktopDropdownItem
