import { FC } from 'react'
import WhyUseJibProps from './WhyUseJib.interface'
import styles from './WhyUseJib.module.scss'

const WhyUseJib: FC<WhyUseJibProps> = ({header, items}) => (
  <div className={`${styles.whyUseJib}`}>
    <h1 className={`${styles.whyUseJib__title}`}>{header}</h1>
      <div className={`${styles['item-container']}`}>
        {items.map((item) => 
            <div className='max-w-xl' key={item.header}>
                <h2 className='text-deepBlue'>{item.header}</h2>
                <p className='text-deepBlue typo-p-large'>{item.subHeader}</p>
            </div>
        )}
      </div>
  </div>
)

export default WhyUseJib
