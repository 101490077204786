import { ReactNode } from 'react'
import { UseFormRegister } from 'react-hook-form'

export interface IStaticData {
  name: string
  email: string
  message: string
  [key: string]: any
}

export interface IFormError {
  name?: {
    errorMessage?: string
  },
  email?: {
    errorMessage?: string
  },
}

export enum ErrorMessageIndex {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface IContactForm {
  onSendMessage: (staticData: IStaticData) => void
  isSuccess? : boolean
  successMessage?: string
  errorMessage?: ReactNode
  formError?: IFormError
  errorMessageIndex?: ErrorMessageIndex,
  register: UseFormRegister<any>
}

