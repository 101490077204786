import React, { createContext, FC, useState, useMemo } from 'react'
import ContactUsContextInterface, { IStaticData, ContactUsContextProviderProps } from './ContactUs.interface'


export const ContactUsContext = createContext<ContactUsContextInterface>({
    sendMessage: () => {},
    success: undefined
})

const ContactUsProvider: FC<ContactUsContextProviderProps> = ({ children }) => {
    const [success, setSuccess] = useState<boolean | undefined>(undefined)

    // Send a message to the contact us API endpoint
    const sendMessage = async (data: IStaticData) => {
        try {
            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_NAME}/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            // const result = await response.json()
            if (response.status === 200) {
                console.log("Success")
                setSuccess(true)
            } else {
                console.log("Failure")
                setSuccess(false)
            }
        } catch (error) {
            console.log("Err")
            setSuccess(false)
        }
    }

    const values: ContactUsContextInterface = useMemo(() => ({
        sendMessage,
        success
    }), [sendMessage, success])

    return (
        <ContactUsContext.Provider value={values}>
            {children}
        </ContactUsContext.Provider>
    )
}

export default ContactUsProvider