import React from 'react'
import Hero from 'components/Hero/Hero'
import Navbar from 'components/Navbar/Navbar'
import HeroTextProps from 'components/HeroText/HeroText.interface'
import TrustedBrands from 'components/TrustedBrands/TrustedBrands'
import WhatIsJib from 'components/WhatIsJib/WhatIsJib'
import Footer from 'components/Footer/Footer'
import ButtonProps, { ButtonVariants } from 'components/Button/Button.interface'
import { useNavigate } from 'react-router-dom'
import styles from './Home.module.scss'

const Home: React.FC = () => {
    const title: string = 'Improving Relationships With Your'
    const secondaryTitle: string = 'Connecting brands and consumers through mobile wallets'
    const navigate = useNavigate()

    const rotatingWords: string[] = [
        'Audience',
        'Fans',
        'Followers',
        'Members',
        'Users',
        'Viewers',
        'Family',
        'Subscribers'
    ]

    const heroTextProps: HeroTextProps = {
        title,
        secondaryTitle,
        rotatingWords,
    }


    const buttons: ButtonProps[] = [
        {
            variant: ButtonVariants.OUTLINE,
            label: "Earn",
            onClick: () => navigate('/solutions/endUsers'),
            className: 'button-hover'
        },
        {
            variant: ButtonVariants.OUTLINE,
            label: "Engage",
            onClick: () => navigate('/solutions/smallBusinesses'),
            className: 'button-hover'
        }
    ]

    return (
        <div className='bg-mutedBlue'>
            <Navbar />
            <div className={`${styles.root}`}>
                <Hero 
                    heroTextProps={heroTextProps}
                    buttons={buttons}
                />
            </div>
            <TrustedBrands />
            <WhatIsJib />
            <Footer />

        </div>
    )
}

export default Home