import { FC, useState, useRef } from 'react'
import { ErrorMessageIndex, IContactForm, IStaticData } from './ContactForm.interface'
import Input from '../Input/Input'
import './ContactForm.styles.scss'
import Button from '../Button/Button'
import { ButtonVariants } from '../Button/Button.interface'
import Notice from '../Notice/Notice'
import { NoticeTypeProps } from '../Notice/Notice.interface'

const ContactForm: FC<IContactForm> = ({
  onSendMessage,
  formError,
  isSuccess,
  successMessage,
  errorMessage,
  errorMessageIndex,
  register
}) => {

  const [staticData, setStaticData] = useState<IStaticData>({ email: '', message: '', name: '' })
  const [isClicked, setIsClicked] = useState<boolean>(false)

  const handleChange = (value: string, field: string) => {
    const data = {...staticData}
    data[field] = value
    setStaticData(data)
  }

  const onClick = () => {
    onSendMessage(staticData)
    setIsClicked(true)
  }
  
  const inputReference = useRef<HTMLTextAreaElement | null>(null)
  const { ref, ...rest } = register('message', {required: false})

  return (
    <div>
      {isClicked && isSuccess === false && errorMessageIndex === ErrorMessageIndex.TOP
        && <Notice text={errorMessage || ''} type={NoticeTypeProps.ERROR}/>}
      <Input
        name='name'
        label="Name*"
        defaultValue={staticData.name}
        customClassName={`${!isSuccess && errorMessageIndex === ErrorMessageIndex.TOP && 'contact-name'}`}
        onChangeValue={(value: string) => handleChange(value, 'name')}
        errorText={formError?.name?.errorMessage}
        register={register}
        validation={{ required: true}}
      />
      <Input
        label="Email Address*"
        name='email'
        defaultValue={staticData.email}
        customClassName="email-address"
        onChangeValue={(value: string) => handleChange(value, 'email')}
        errorText={formError?.email?.errorMessage}
        register={register}
        validation={{ required: true}}
      />
      <div className="message">
        <label htmlFor="message">
          What can we help you with?
          <textarea
            {...rest}
            ref={(e) => {
              ref(e)
              inputReference.current = e
            }}
            name='message'
            id='message'
            rows={6}
            onChange={(e) => handleChange(e.target.value, 'message')}
            defaultValue={staticData.message}
          />
        </label>
      </div>
      {isClicked && isSuccess === false && errorMessageIndex === ErrorMessageIndex.BOTTOM
        && <Notice text={errorMessage || ''} className="mt-8" type={NoticeTypeProps.ERROR}/>}
      {isClicked && isSuccess && <div className="success-message"><Notice text={successMessage} type={NoticeTypeProps.SUCCESS} /></div>}
      <Button
        label="Send message"
        variant={ButtonVariants.PRIMARY}
        className="btn-send-message"
        onClick={onClick}
      />
    </div>
  )
}

export default ContactForm
