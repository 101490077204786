import { FC } from 'react'
import SellingPointsProps from './SellingPoints.interface'
import styles from './SellingPoints.module.scss'

const SellingPoints: FC<SellingPointsProps> = ({ sellingPoints }) => (
  <div className={`${styles.root}`}>
    {sellingPoints.map((sellingPoint) => 
      <div className={`${styles['selling-point-container']}`} key={sellingPoint.title}>
        {sellingPoint.icon}
        <div className={`${styles['selling-point-text-container']}`}>
          <p className={`${styles.text}`}><b>{sellingPoint.title}</b></p>
          <p className={`${styles.text__sub}`}>{sellingPoint.subtitle}</p>
        </div>
      </div>
    )}
  </div>
)

export default SellingPoints
