import { ReactNode } from 'react'

export enum NoticeTypeProps {
  ALERT = 'alert',
  SUCCESS = 'success',
  ERROR = 'error',
}

export default interface NoticeProps {
  text?: ReactNode
  className?: string
  type: NoticeTypeProps
}
