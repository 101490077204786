import Footer from 'components/Footer/Footer'
import Hero from 'components/Hero/Hero'
import HowToGetStarted from 'components/HowToGetStarted/HowToGetStarted'
import Navbar from 'components/Navbar/Navbar'
import SellingPoints from 'components/SellingPoints/SellingPoints'
import WhyUseJib from 'components/WhyUseJib/WhyUseJib'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import solutionsData from '../../data/SolutionData/solutionsData'


type SolutionParams = {
    id: string
}

const SolutionDetail: FC = () => {
    const { id } = useParams<keyof SolutionParams>() as SolutionParams

    return (
        <>
            <Navbar />
            <div className='bg-deepBlue pt-10 pb-32'>
                <Hero 
                    heroTextProps={{
                        title: solutionsData[id].heroHeader,
                        secondaryTitle: solutionsData[id].heroSubHeader
                    }}
                />
                <SellingPoints 
                    sellingPoints={solutionsData[id].sellingPoints}
                />
            </div>
            <WhyUseJib 
                header={solutionsData[id].whyUseJib.header}
                items={solutionsData[id].whyUseJib.items}
            />
            <HowToGetStarted 
                title="How Does It Work?"
                steps={solutionsData[id].howToGetStartedItems}
            />
            <Footer />
            
        </>
    )
}

export default SolutionDetail