import { FC } from 'react'
import ErrorBanner from '../Icon/ErrorBanner'
import Success from '../Icon/Success'
import TriangleExclamationIcon from '../Icon/TriangleExclamation'
import NoticeProps, { NoticeTypeProps } from './Notice.interface'
import styles from './Notice.module.scss'

const renderIcon = (type: NoticeTypeProps) => {
  switch (type) {
    case NoticeTypeProps.ALERT:
      return <TriangleExclamationIcon width={13.5} height={15} className={`${styles[type]}`} />

    case NoticeTypeProps.SUCCESS:
      return <Success width={19} height={20} className={`${styles[type]}`} />

    case NoticeTypeProps.ERROR:
      return <ErrorBanner width={22} height={20} className={`${styles[type]}`} />

    default:
      return <TriangleExclamationIcon width={13.5} height={15} className={`${styles[type]}`} />
  }
}

const Notice: FC<NoticeProps> = ({ text, type, className }) =>
  <div className={`${styles.root} ${styles[`${type}-root`]} ${className}`}>
    {renderIcon(type)}
    <span>{text}</span>
  </div>

export default Notice
