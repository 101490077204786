import { FC } from 'react'
import { motion } from 'framer-motion'
import HeroText from '../HeroText/HeroText'
import Button from '../Button/Button'
import HeroProps from './Hero.interface'
import styles from './Hero.module.scss'

const Hero: FC<HeroProps> = ({heroTextProps, imageChildren, buttons}) => (

  <div className={`${styles.root}`}>
    <motion.div
        animate={{opacity: 1}}
        initial={{opacity: 0}}
        transition={{ ease: "easeIn", duration: 1 }}
        className={`${styles['text-container']}`}
    >
      <HeroText 
        title={heroTextProps.title}
        rotatingWords={heroTextProps.rotatingWords}
        secondaryTitle={heroTextProps.secondaryTitle}
      />
      {buttons && 
        <div className={`${styles['button-container']}`}>
          {buttons.map((button) => <Button variant={button.variant} label={button.label} onClick={button.onClick} key={button.label}/>)}
        </div>}
    </motion.div>
    {imageChildren}
  </div>
)

export default Hero

