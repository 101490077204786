import { FC, useState, useEffect } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import HeroTextProps from './HeroText.interface'
import styles from './HeroText.module.scss'


const HeroText: FC<HeroTextProps> = ({title, rotatingWords, secondaryTitle}) => {
  const [index, setIndex] = useState(0)

  const variants = {
    enter: () => (
      {
        y: -20,
        opacity: 0
      }
    ),
    center: {
      zIndex: 1,
      y: 0,
      opacity: 1
    },
    exit: () => (
      {
        zIndex: 0,
        opacity: 0
      }
    )
  }

  if (rotatingWords) {
    useEffect(() => {
      setTimeout(() => {
          let next = index + 1
          if (next === rotatingWords.length) {
              next = 0
          }
          setIndex(next)
      }, 1500)
    }, [index, setIndex])
  }

  return (
    <div className={`${styles.root}`}>
      <h1 className={`${styles['primary-title']}`}>
        {title}
        {rotatingWords && (
          <AnimatePresence>
            <motion.span
              style={{ position: "absolute" }}
              className="text-brightBlueLight z-0"
              variants={variants}
              key={index}
              initial='enter'
              animate='center'
              exit='exit'
              transition={{
                y: { type: "spring", stiffness: 750, damping: 150 },
                opacity: { duration: 0.25 }
            }}
            >
              &nbsp;{rotatingWords[index]}
            </motion.span>
          </AnimatePresence>
        )}
      </h1>
      {secondaryTitle && (
        <h2 className={`${styles['secondary-title']}`}>{secondaryTitle}</h2>
      )}
    </div>
  )
}

export default HeroText
