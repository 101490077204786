import Footer from 'components/Footer/Footer'
import Navbar from 'components/Navbar/Navbar'
import { FC, useContext, useEffect } from 'react'
import { ContactUsContext } from 'context/ContactUs/ContactUs'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ContactForm from 'components/ContactForm/ContactForm'
import { IStaticData, ErrorMessageIndex } from 'components/ContactForm/ContactForm.interface'


const ContactUs: FC = () => {
    const { success, sendMessage } = useContext(ContactUsContext)

    const schema = yup.object().shape({
        name: yup.string().required('Your must enter a name'),
        email: yup.string().email().required('You must enter a valid email address'),
        message: yup.string().notRequired()
    })

    const formOptions = { resolver: yupResolver(schema) }
    const { handleSubmit, register, formState: {errors} } = useForm<IStaticData>(formOptions)

    const onSubmit: SubmitHandler<IStaticData> = async (data) => {
        sendMessage(data)
    }

    useEffect(() => {
        console.log(success)
        console.log(!success)
        console.log(!null)
    })

    return (
        <div>
            <Navbar />
            <div className='bg-deepBlue text-center py-40 rounded-b-3xl'>
                <h1 className='text-white'>Lets Get Started</h1>
            </div>
            <div className='py-32 px-10 md:px-32 md:flex justify-between'>
                <div className='pb-5 md:pb-0 md:w-5/12'>
                    <h1 className='text-deepBlue'>Have Questions?</h1>
                    <p className='typo-p-small  text-grey'>We’ve got you covered. Fill out the form below to get in contact with a representative. Please allow 24 hours for a response to your email.</p>
                </div >
                <div className='my-10 md:my-0 md:w-5/12'>                    
                    <ContactForm 
                        onSendMessage={() => handleSubmit(onSubmit)()}
                        successMessage='We’ve received your request. We’ll be in touch!'
                        errorMessage='Something went wrong. Please try again.'
                        errorMessageIndex={ErrorMessageIndex.TOP}
                        isSuccess={success}
                        register={register}
                        formError={{
                            name: {
                                errorMessage: errors.name?.message
                            },
                            email: {
                                errorMessage: errors.email?.message
                            }
                        }}
                    />
                </div>
            </div>
            <Footer />
            
        </div>
    )
}

export default ContactUs