import SolutionDataInterface from "./solutionsDataInterface"

const solutionsData: SolutionDataInterface = {
    brands: {
        title: 'For Brands',
        subtitle: 'Improve loyalty and retention',
        heroHeader: <b>Generate <span className='text-brightBlueLight'>thousands</span> in additional sells per month.</b>,
        heroSubHeader: 'Creates Offers for customers in minutes, with no code and near real time insights',
        sellingPoints: [
            {
                title: 'Increase LTV, Reduce CAC',
                subtitle: 'Lifetime value of your customer goes up, your costs to acquire customers goes down',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            },
            {
                title: 'First party data',
                subtitle: 'No third party data tracking through cookies. All data attribution are done though Jibs Funnel',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                </svg>
            },
            {
                title: 'Near real time insights',
                subtitle: 'Custom built dashboard provides valuable insight to show you that your marketing campaign is successful',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                </svg>
            }
        ],
        whyUseJib: {
          header: <>Why <span className='text-brightBlueLight'>Brands</span> Use Jib</>,
          items: [
            {
              header: "Reduce Fraud",
              subHeader: "Prevent coupon duplication and reconciliation issues"
            },
            {
              header: "Increase Sales",
              subHeader: "disruptive marking method to drive business"
            },
            {
              header: "No Code",
              subHeader: "Don't have a tech team? No problem. You don't need one."
            },
            {
              header: "Incentive not Discount",
              subHeader: "Drive more sales by offering fee things not discounts"
            },
            {
              header: "Easy to Use",
              subHeader: "Professionally designed console to streamline offer creation"
            },
            {
              header: "Infinitely Scalable",
              subHeader: "Jib cards can be used at any point of sale where mastercard is accepted"
            },
          ]
        },
        howToGetStartedItems: [
          {
            title: "Create an Account",
            content: "Create an account and then follow instructions to create your offers!",
          
          },
          {
            title: "Establish Your Goals and Create Your Budget",
            content: "Jib will walk you through the process in our custom console of setting up your goals for each offer and establishing your budget",
          
          },
          {
            title: "Create Offers and Go Live in Minutes",
            content: "Your offer is now live and you can begin rewarding customers, while viewing near realtime data on fulfillments! Either market your offer yourself of level jib channel of partners and agencies to help you get fulfillments."
          },
          {
            title: "Real mastercard's for Your Customers",
            content: "Any customer that opts into your offer will be sent a real open loop mastercard that can be loaded into any digital wallet and used at any point of sell that accepts mastercard in the world including online."
          }
        ]
    },
    agencies: {
        title: 'For Agencies',
        subtitle: 'Reaffirm engagement and fulfillment',
        heroHeader: <b>Capture New brands and increase <span className='text-brightBlueLight'>Ad Revenue</span></b>,
        heroSubHeader: 'Create enticing offers using Jibs fulfillment tool',
        sellingPoints: [
            {
                title: 'Increase LTV, Reduce CAC',
                subtitle: 'Market disruptive tools to help you increase metrics while reducing marketing costs',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              
              },
              {
                title: 'First party data',
                subtitle: 'Market disruptive tools to help you increase metrics while reducing marketing costs',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                  </svg>
              },
              {
                title: 'Near real time insights',
                subtitle: 'Market disruptive tools to help you increase metrics while reducing marketing costs',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
              </svg>
              
              }
        ],
        whyUseJib: {
          header: <>Why <span className='text-brightBlueLight'>Agencies</span> Use Jib</>,
          items: [
            {
              header: "Reduce Fraud",
              subHeader: "Prevent coupon duplication and reconciliation issues"
            },
            {
              header: "Increase Sales",
              subHeader: "disruptive marking method to drive business"
            },
            {
              header: "No Code",
              subHeader: "Don't have a tech team? No problem. You don't need one."
            },
            {
              header: "Incentive not Discount",
              subHeader: "Drive more sales by offering fee things not discounts"
            },
            {
              header: "Easy to Use",
              subHeader: "Professionally designed console to streamline offer creation"
            },
            {
              header: "Infinitely Scalable",
              subHeader: "Jib cards can be used at any point of sale where mastercard is accepted"
            },
          ]
        },
        howToGetStartedItems: [
          {
            title: "Create an Account",
            content: "Create an account and then follow instructions to create your offers!"
          },
          {
            title: "Establish Your Goals and Create you Budget",
            content: "Jib will walk you through the process in our custom console of setting up your goals for each offer and establishing your budget"
          },
          {
            title: "Create Offers and Go Live in Minutes",
            content: "Your offer is now live and you can begin rewarding customers, while viewing near realtime data on fulfillments! Either market your offer yourself of level jib channel of partners and agencies to help you get fulfillments."
          },
          {
            title: "Real mastercard's for your customers",
            content: "Any customer that opts into your offer will be send a real open loop mastercard that can be loaded into any digital wallet and used at any point of sell that accepts mastercard in the world including online."
          }
        ]
    },
    partners: {
        title: 'For Partners',
        subtitle: 'Monetize your audience',
        heroHeader: <b>Monetize your customers and generate <span className='text-brightBlueLight'>new</span> revenue streams</b>,
        heroSubHeader: "Offer your customers a card that doesn't require a credit check and can be used at any point of sale that accepts mastercard",
        sellingPoints: [
            {
                title: 'Data driven insights',
                subtitle: 'Data on how your customers are spending their money, so can optimize your marketing spend',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                </svg>
              
              },
              {
                title: 'Increase customer satisfaction',
                subtitle: 'Use your customers as a channel to offer them incentives and rewards all while increasing customer satisfaction',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                </svg>
              
              },
              {
                title: 'Add value for your sponsors',
                subtitle: 'Your sponsors will be able to offer incentives and rewards to your customers and see real results',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                </svg>
              
              }
        ],
        whyUseJib: {
          header: <>Why <span className='text-brightBlueLight'>Partners</span> Use Jib</>,
          items: [
            {
              header: "Be Creative",
              subHeader: "Implement new and creative ways to reward your customers"
            },
            {
              header: "Earn revenue share",
              subHeader: "Earn a share of revenue by offering your customers incentives and rewards from your sponsors or other brands in Jibs channel"
            },
            {
              header: "No Code",
              subHeader: "Don't have a tech team? No problem. You don't need one."
            },
            {
              header: "New Benefits for your audience",
              subHeader: "Jib offers your audience new benefits and rewards that they can't get anywhere else"
            },
            {
              header: "Easy to Use",
              subHeader: "Professionally designed console to streamline offer creation, attribution tracking, and reporting"
            },
            {
              header: "Infinitely Scalable",
              subHeader: "Jib cards can be used at any point of sale where mastercard is accepted"
            },
          ]
        },
        howToGetStartedItems: [
          {
            title: "Contact Jib",
            content: "Contact Jib to get started. We will work with you to understand your needs and goals and help you get started."
          },
          {
            title: "Customize your Jib Card",
            content: "Customize your loyalty card with your logo and colors. This card will be used by your customers to earn rewards and incentives."
          },
          {
            title: "Market offers to your customers",
            content: "Market offers from your sponsors or offers from Jib channel to your customers, by using custom links or QR codes."
          },
          {
            title: "Watch revenue grow",
            content: "Watch though you revenue stream grow and grow though the Jib console as your customers redeem offers."
          }
        ]
    },
    smallBusinesses: {
        title: 'For Small Businesses',
        subtitle: 'Acquire new customers',
        heroHeader: <b>Engage New Customers <span className='text-brightBlueLight'>Anytime, Anywhere</span></b>,
        heroSubHeader: 'Drive new customers into your growing business and increase sales',
        sellingPoints: [
            {
                title: 'Covert leads into sales',
                subtitle: 'Dive new customers to your business by offering them a free gift',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              
              },
              {
                title: 'Retain existing customers',
                subtitle: 'Incentivize your existing customers to come back to your business',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
              </svg>
              
              },
              {
                title: 'Track Repeat Visits',
                subtitle: 'Get near real time insights on how many times your customers are coming back to your business',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>
              
              }
        ],
        whyUseJib: {
          header: <>Why <span className='text-brightBlueLight'>Small Businesses</span> Use Jib</>,
          items: [
            {
              header: "Reduce Fraud",
              subHeader: "Prevent coupon duplication and reconciliation issues"
            },
            {
              header: "Increase Sales",
              subHeader: "disruptive marking method to drive business"
            },
            {
              header: "No Code",
              subHeader: "Don't have a tech team? No problem. You don't need one."
            },
            {
              header: "Incentive not Discount",
              subHeader: "Drive more sales by offering fee things not discounts"
            },
            {
              header: "Easy to Use",
              subHeader: "Professionally designed console to streamline offer creation"
            },
            {
              header: "Powerful Tools",
              subHeader: "Jib allows small business owners to enjoy the same marketing tools that large CPG Brands and chain operators enjoy."
            },
          ]
        },
        howToGetStartedItems: [
          {
            title: "Create an Account",
            content: "Create an account and then follow instructions to create your offers!"
          },
          {
            title: "Establish Your Goals and Create you Budget",
            content: "Jib will walk you through the process in our custom console of setting up your goals for each offer and establishing your budget"
          },
          {
            title: "Create Offers and Go Live in Minutes",
            content: "Your offer is now live and you can begin rewarding customers, while viewing near realtime data on fulfillments! Either market your offer yourself of level jib channel of partners and agencies to help you get fulfillments."
          },
          {
            title: "Real mastercard's for your customers",
            content: "Any customer that opts into your offer will be send a real open loop mastercard that can be loaded into any digital wallet and used at any point of sell that accepts mastercard in the world including online."
          }
        ]
    },
    endUsers: {
        title: 'For End Users',
        subtitle: 'Benefits and Features',
        heroHeader: <b>Earn from your favorite brands <span className='text-brightBlueLight'>anytime, anywhere</span></b>,
        heroSubHeader: 'Get cash for being a loyal customer',
        sellingPoints: [
            {
                title: 'No strings Attached',
                subtitle: "You don't have to pay anything to use Jib. No monthly fees, no hidden fees, no fees at all.",
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 9l10.5-3m0 6.553v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 11-.99-3.467l2.31-.66a2.25 2.25 0 001.632-2.163zm0 0V2.25L9 5.25v10.303m0 0v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 01-.99-3.467l2.31-.66A2.25 2.25 0 009 15.553z" />
                </svg>
              
              },
              {
                title: 'Its simple to Use',
                subtitle: 'Just add your card to your wallet and start using it. No need to download an app or create an account.',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                </svg>
              
              },
              {
                title: 'No Credit Check',
                subtitle: 'No credit check is required to use Jib. You can use it right away.',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                </svg>
              
              }
        ],
        whyUseJib: {
          header: <>Why <span className='text-lightBlue'>EndUsers</span> Use Jib</>,
          items: [
            {
              header: "Cash for bring a customer",
              subHeader: "Get cash for being a loyal customer"
            },
            {
              header: "Accepted anywhere",
              subHeader: "Virtual card can be used anywhere Mastercard is accepted"
            },
            {
              header: "Add to any mobile wallet",
              subHeader: "Add your Jib card to any mobile wallet"
            },
            {
              header: "Incentive not Discount",
              subHeader: "Drive more sales by offering fee things not discounts"
            },
            {
              header: "Easy to Use",
              subHeader: "No need to sign up. Just add your card to your wallet and start using it"
            },
            {
              header: "Use online or in store",
              subHeader: "Jib cards can be not just in store but also online"
            },
          ]
        },
        howToGetStartedItems: [
          {
            title: "Click a link or scan QR code",
            content: "You decide on what offers you want to opt in to. No sign up required. Just click a link or scan a QR code."
          },
          {
            title: "Receive your virtual card",
            content: "After you opt into an offer you will receive your virtual card though email or text message. Then you can add it to your mobile wallet."
          },
          {
            title: "Go Redeem your offer",
            content: "Go to the store and redeem your offer. You can use your virtual card anywhere Mastercard is accepted. Its that simple."
          }
        ]
    },
    momNPops: {
        title: 'For Mom & Pops',
        subtitle: 'Create & send instant gifts',
        heroHeader: <b>Create and Send <span className='text-brightBlueLight'>Instant</span> gifts</b>,
        heroSubHeader: 'Create and send instant gifts to family and friends or kids',
        sellingPoints: [
            {
                title: 'Reload-able Virtual Cards',
                subtitle: 'Continue to load over and over gits to your best friends and family',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                </svg>
              
              },
              {
                title: 'It’s simple',
                subtitle: 'Just add your card to your wallet and start using it. No need to download an app or create an account.',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                </svg>
              
              },
              {
                title: 'No plastic',
                subtitle: 'No need to carry around plastic cards. Just use your phone to pay.',
                icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-80 h-36 text-brightBlueLight mx-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                </svg>
              
              },
        ],
        whyUseJib: {
          header: <>Why <span className='text-lightBlue'>Mom & Pops</span> Use Jib</>,
          items: [
            {
              header: "Echo Friendly",
              subHeader: "Just create an account and create a card."
            },
            {
              header: "Secure",
              subHeader: "Virtual card is load into into the mobile wallet where it is safe and secure"
            },
            {
              header: "Accepted anywhere",
              subHeader: "Virtual card can be used anywhere Mastercard is accepted"
            }
          ]
        },
        howToGetStartedItems: [
          {
            title: "Create an Account",
            content: "Create and account though our console."
          },
          {
            title: "Choose who you want to give to and how much",
            content: "Using the Jib console you can create a virtual card and load it with the amount you want to give."
          },
          {
            title: "Fund anyway you want",
            content: "Fund the virtual card with a credit card, debit card, or bank account."
          }
        ]
    }
}
export default solutionsData