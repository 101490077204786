import { FC } from 'react'
import AccordionProps from './Accordion.interface'
import styles from './Accordion.module.scss'
import AccordionItem from './AccordionItem/AccordionItem'

const Accordion: FC<AccordionProps> = ({ items }) => (
  <div className={`${styles.root}`}>
    <h2 className={`${styles.headline}`}>Frequently asked questions</h2>
    <div className={`${styles.list}`}>
      {items.map(({ description, detail }) => (
        <AccordionItem key={description} description={description} detail={detail} />
      ))}
    </div>
  </div>
)

export default Accordion
