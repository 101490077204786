import Navbar from 'components/Navbar/Navbar'
import NoOffersPlaceholder from 'components/NoOffersPlaceholder/NoOffersPlaceholder'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

const UnderConstruction: FC = () => {
    const navigate = useNavigate()
    return (
        <div>
            <Navbar />
            <div className='h-screen'>
                <NoOffersPlaceholder 
                    title='This Page is Under Construction'
                    content='We are still in the process of redesigning our site. Please come back to this page later!'
                    buttonLabel='Go to Home Page'
                    onClick={() => navigate('/')}
                />
            </div>
        </div>
    )
}

export default UnderConstruction