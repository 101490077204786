import React, { FunctionComponent } from 'react'
import IconProps from './Icon.interface'

const InfoIcon: FunctionComponent<IconProps> = ({
  color = '#027FAC',
  width = 24,
  height = 24,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.9994 0C18.6227 0 24 5.37734 24 11.9994C24 18.6227 18.6227 24 11.9994 24C5.37734 24 0 18.6227 0 11.9994C0 5.37734 5.37734 0 11.9994 0ZM11.9994 1.80045C6.36999 1.80045 1.80045 6.36999 1.80045 11.9994C1.80045 17.6288 6.36999 22.1995 11.9994 22.1995C17.6288 22.1995 22.1995 17.6288 22.1995 11.9994C22.1995 6.36999 17.6288 1.80045 11.9994 1.80045ZM11.9994 9.6024C11.5025 9.6024 11.0992 10.0057 11.0992 10.5026V17.1043C11.0992 17.6012 11.5025 18.0045 11.9994 18.0045C12.4963 18.0045 12.8996 17.6012 12.8996 17.1043V10.5026C12.8996 10.0057 12.4963 9.6024 11.9994 9.6024ZM11.997 6.0015C12.6596 6.0015 13.1973 6.53924 13.1973 7.2018C13.1973 7.86437 12.6596 8.4021 11.997 8.4021C11.3344 8.4021 10.7967 7.86437 10.7967 7.2018C10.7967 6.53924 11.3344 6.0015 11.997 6.0015Z"
      fill={color}
    />
  </svg>
)

export default InfoIcon
