import { FC } from 'react'
// import { Link } from 'react-router-dom'
import { AiFillTwitterSquare, AiFillFacebook, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai'
import FooterProps from './Footer.interface'
import JibLogo from '../../images/jib-logo.png'
import styles from './Footer.module.scss'

const Footer: FC<FooterProps> = () => (
  // const [privacyPolicyIsOpen, setPrivacyPolicyIsOpen] = useState(false)
  // console.log(privacyPolicyIsOpen)
  // setPrivacyPolicyIsOpen(true)tfr
    <div className={`${styles.footer}`}>
      <p className={`${styles.footer__mission}`}>Jib is redefining B2C relationships to better meet the needs of today’s empowered consumer(B=C).The Jib platform is a single destination for businesses of all shapes and sizes to create and launch mobile wallet-based programs that power loyalty, rewards, gifts, memberships, incentives, and other opt-in customer solutions. We call this “Opt-In Advertising&trade;”</p>
      <div className={`${styles.footer__bottom}`}>
        <div>
          <img src={JibLogo} alt='Jib Logo' className={`${styles.footer__image}`} />
          <p className={`${styles['footer__copy-right-text']}`}>© 2022 Jib Technologies, Inc.</p>
        </div>
        <div className={`${styles['footer__social-media-wrapper']}`}>
          <div className={`${styles['footer__social-media-container']}`}>
            <a href='https://twitter.com/jibtechnologies'>
              <AiFillTwitterSquare  className={`${styles['footer__social-media-icon']}`} />
            </a>
            <a href='https://www.facebook.com/Jibbin/'>
                <AiFillFacebook className={`${styles['footer__social-media-icon']}`} />
            </a>
          </div>
          <div className={`${styles['footer__social-media-container']}`}>
            <a href='https://www.instagram.com/jib_tech/'>
              <AiFillInstagram className={`${styles['footer__social-media-icon']}`} />
            </a>
            <a href='https://www.linkedin.com/company/jib-technologies-inc/'>
              <AiFillLinkedin className={`${styles['footer__social-media-icon']}`} />
            </a>
          </div>
        </div>

      </div>
    </div>
)

export default Footer


