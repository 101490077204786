import { FC } from 'react'
import TrustedBrandsProps from './TrustedBrands.interface'
import images from './images'
import styles from './TrustedBrands.module.scss'

const TrustedBrands: FC<TrustedBrandsProps> = () => (
  <div className={`${styles.root}`}>
    <h1 className={`${styles.root__header}`}>Trusted by the world&apos;s most popular brands</h1>
      <div className={`${styles.root__images}`}>
        {images.map((image) => <img src={image} alt='Brand Logo' className={`${styles.root__image}`} key={image}/>)}
      </div>
  </div>
)

export default TrustedBrands

