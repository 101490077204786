const faqs = [
    {
        description: "What are Jib Gift Cards?",
        detail: "Jib Gift Cards are virtual gift cards that consumers can create, personalize, buy, share, and redeem, directly from a mobile phone. Jib has combined the emotional connection of gift-giving, with the speed, ease, and security of mobile payments, to completely re-imagine the gift card."
    },
    {
        description: "How do Jib Gift Cards work?",
        detail: "Business owners can create, promote and sell their own gift cards to their customers, who, in turn, can purchase, personalize and send to anyone, anywhere, anytime, directly on their mobile phones. Business owners can also load funds onto gift cards and send to their existing customers as a promotional incentive, a loyalty reward, or even in response to a customer service issue. A gift card recipient simply adds the card to their mobile wallet, and taps to pay when it’s time to redeem it."
    },
    {
        description: "Are Jib Gift Cards secure?",
        detail: "Yes! Jib uses the same secure banking technology and payments infrastructure, and is subject to the same regulatory compliance, as all the major card networks. So, our cards are as secure as any brand name credit or debit card in your mobile wallet."
    },
    {
        description: "Can I sell gift cards to my customers?",
        detail: "Of course! As a merchant, all you need is an active member account to create, promote, and sell your own video gift cards, powered by Jib. Premium membership benefits include your gift card being featured in the Jib Gift Card Mall. You can promote your very own gift card link or gift card QR-code via in-store signage, your website, social media and messaging platforms, or send directly to your customers via text or email. As soon as you create your gift card and activate it on the Jib platform, you can let your customers know gift cards are now available for purchase."
    },
    {
        description: "Are all business types eligible?",
        detail: "No. Unfortunately, we can’t activate gift cards for every type of business due to federal regulations we must follow as a financially regulated company. Please reach out to our Support Team if you have any descriptions or concerns."
    }
]

export default faqs