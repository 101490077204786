import { ChangeEvent, FC, useState, useRef } from 'react'
import InputProps from './Input.interface'
import styles from './Input.module.scss'


const Input: FC<InputProps> = ({
  id,
  name,
  label,
  errorText,
  disabled,
  onChangeValue,
  defaultValue,
  placeholder,
  onClick,
  customClassName,
  register,
  validation,
}) => {
  const [valueInput, setValueInput] = useState(defaultValue)
  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onChangeValue && onChangeValue(e.target.value)
      setValueInput(e.target.value)
  }

  const inputReference = useRef<HTMLInputElement | null>(null)
  const {ref, ...rest} = register(name, {...validation})

  return <div className={`${styles.root} ${customClassName}`}>
    <label className={`${errorText && styles['error-label']}`} htmlFor={id}>{label}</label>
    <input
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      ref={(e) => {
        ref(e)
        inputReference.current = e
      }}
      id={id}
      className={`${errorText ? styles['error-input'] : styles.input}`}
      type="text"
      disabled={disabled}
      value={valueInput}
      placeholder={placeholder}
      onChange={(e) => onChangeInput(e)}
      onClick={onClick}
    />
    {errorText && <span className={`${styles['error-message']}`}>{errorText}</span>}
  </div>
}

export default Input
