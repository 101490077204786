import { FC } from 'react'
import HowToGetStartedProps from './HowToGetStarted.interface'
import styles from './HowToGetStarted.module.scss'

const HowToGetStarted: FC<HowToGetStartedProps> = ({title, steps}) => (
  <div className={`${styles.root}`}>
    <h1 className={`${styles.header}`}>{title}</h1>
    <div className={`${styles['step-container']}`}>
      {steps.map((item, index) => 
        <div className='max-w-xl' key={item.title}>
            <h2 className={`${styles.text}`}>{index + 1}. {item.title}</h2>
            <p className={`${styles.text__sub}`}>{item.content}</p>
        </div>
      )}
    </div>
  </div>
)

export default HowToGetStarted
