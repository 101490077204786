import { FC } from 'react'
import WhatIsJibProps from './WhatIsJib.interface'
import styles from './WhatIsJib.module.scss'

const WhatIsJib: FC<WhatIsJibProps> = () => (
  <div className={`${styles.root}`}>
    <h1 className={`${styles.root__header}`}>What is Jib?</h1>
    <p className={`${styles.root__body}`}>Jib Technologies offers a no-code software solution enabling advertisers to offer consumers cash rewards in exchange for data and engagement.</p>
  </div>
)

export default WhatIsJib
