import { FC, useState } from 'react'
import classNames from 'classnames'
import AccordionItemProps from './AccordionItem.interface'
import styles from './AccordionItem.module.scss'
import Icon from '../../Icon/Icon'

const AccordionItem: FC<AccordionItemProps> = ({ description, detail }) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={`${styles.root}`}>
      <div className={`${styles.topContainer}`} onClick={() => setOpen(!open)} role="presentation">
        <div className={`${styles.description}`}>{description}</div>
        {open ? (
          <button type="button" className={`${styles.button}`}>
            <Icon.CloseCircle width={37} height={37} />
          </button>
        ) : (
          <button type="button" className={`${styles.button}`}>
            <Icon.PlusCircle />
          </button>
        )}
      </div>
      <div
        className={classNames(styles.detail, {
          [styles.detailOpen]: open,
        })}
      >
        {detail}
      </div>
    </div>
  )
}

export default AccordionItem
