import Navbar from 'components/Navbar/Navbar'
import { FC } from 'react'
import Accordion from 'components/Accordion/Accordion'
import faqs from 'data/GeneralQuestionsData/GeneralQuestionsData'
import Footer from 'components/Footer/Footer'
import styles from './Faq.module.scss'

const Faq: FC = () => (
    <>
    <div className='bg-mutedBlue'>
        <Navbar />
        <div className='py-32 bg-deepBlue rounded-b-3xl'>
            <div className='text-center text-white'>
                <h1>Have Questions?</h1>
                <p className='typo-p-large'>Explore our FAQ&apos;s for the most common questions, or reach out for more answers.</p>
            </div>
        </div>
        <div className='px-5 md:px-20 py-20'>
            <div className={`${styles['accordion-container']}`}>
                <Accordion 
                    items={faqs}
                />
            </div>
        </div>
    </div>
    <Footer />
    </>
)

export default Faq