import { FC } from 'react'
import ButtonProps from './Button.interface'
import styles from './Button.module.scss'

const Button: FC<ButtonProps> = ({
  variant,
  label,
  onClick,
  disabled,
  className,
  type,
}) => (
  <div className={`${className}`}>
    <button
      className={`${styles.root} ${styles[variant]}`}
      /* eslint-disable react/button-has-type */
      type={type || 'button'}
      /* eslint-enable react/button-has-type */
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  </div>
)

export default Button
