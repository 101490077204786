import { FC, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {motion, AnimatePresence} from 'framer-motion'
import DesktopDropdown from '../DesktopDropdown/DesktopDropdown'
import { ButtonVariants } from '../Button/Button.interface'
import Button from '../Button/Button'
import NavbarProps from './Navbar.interface'
import styles from './Navbar.module.scss'
import JibLogo from '../../images/jib-logo.png'
import solutionsData from '../../data/SolutionData/solutionsData'
import IconComponent from '../Icon/Icon'


const Navbar: FC<NavbarProps> = () => {
  const [mobileMenu, setMobileMenu] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [rotate, setRotate] = useState(0)
  const navigate = useNavigate()

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu)
  }

  const handleOpenSolutions = () => {
    if ( isOpen ) {
        setRotate(0)
    } else {
        setRotate(180)
    }
    setIsOpen(!isOpen)
  }

  return (
    <div className={`${styles.wrapper}`}>
      {/* Mobile Menu */}
      <div className={`${styles['mobile-menu']} ${mobileMenu && styles['open-menu']}`}>
        <div 
          className={`${styles['mobile-menu__close']}`}
          onClick={handleMobileMenu}
          aria-hidden="true"
        >
          <IconComponent.CloseCircle width={57} height={57} />
        </div>
        <div className={`${styles['mobile-menu__nav-items']}`}>
          <Link to='/faq' className={`${styles['mobile-menu__nav-item']} ${mobileMenu && styles['fade-in-text']}`} onClick={handleMobileMenu}>About Us</Link>
          <Link to='/faq' className={`${styles['mobile-menu__nav-item']} ${mobileMenu && styles['fade-in-text']}`} onClick={handleMobileMenu}>Resources</Link>
          <div 
            className='flex justify-between'
            onClick={handleOpenSolutions}
            aria-hidden="true"
          >
            <span className={`${styles['mobile-menu__nav-item']} ${mobileMenu && styles['fade-in-text']}`}>Solutions</span>
            <motion.svg 
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className={`${styles['mobile-menu__nav-item']} ${mobileMenu && styles['fade-in-text']} h-12 w-12`}
              animate={{ rotate }}
              transition={{ duration: 0.01 }}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </motion.svg>
          </div>
          <AnimatePresence initial={false}>
            {isOpen && (
              <motion.section
              className='overflow-hidden flex flex-col'
                key='content'
                initial='collapsed'
                animate='open'
                exit='collapsed'
                variants={{
                  open: { opacity: 1, height: 'auto' },
                  collapsed: { opacity: 0, height: 0 }
                }}
                transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                {Object.keys(solutionsData).map((key) => (
                  <Link to={`/solutions/${key}`} key={solutionsData[key].title} className={`${styles['mobile-menu__nav-item-dropdown']} ${mobileMenu && styles['fade-in-text']}`} onClick={handleMobileMenu}>{solutionsData[key].title}</Link>
                ))}
              </motion.section>
            )}
          </AnimatePresence>
          <Link to='/login' className={`${styles['mobile-menu__nav-item']} ${mobileMenu && styles['fade-in-text']}`} onClick={handleMobileMenu} >Login</Link>
          <Link to='/contact_us' className={`${styles['mobile-menu__nav-item']} ${mobileMenu && styles['fade-in-text']}`} onClick={handleMobileMenu} >Get Started</Link>
        </div>
      </div>

      {/* Navbar */}
      <div className={`${styles.navbar}`}>

        <div className={`${styles.navbar__nav_items}`}>
          <div className={`${styles.navbar__image}`} onClick={() => navigate('/')} aria-hidden="true">
              <img src={JibLogo} alt="JibLogo" />
            </div>

          {/* xl screen, lg screen */}
          <div className={`${styles.navbar__nav_items__large}`}>

            <Link to="/about_us" className={`${styles.navbar__nav}`}>About Us</Link>
            <Link to="/faq" className={`${styles.navbar__nav}`}>Resources</Link>
            <DesktopDropdown title='Solutions' dropdownItems={Object.keys(solutionsData).map((key) => ({
              title: solutionsData[key].title,
              subtitle: solutionsData[key].subtitle,
              link: key
            }))}/>
          </div>
        </div>

        <div className={`${styles.navbar__nav_items__large}`}>
          <Link to="/login" className={`${styles.navbar__nav}`}>Login</Link>
          <div className='pr-20 hidden lg:flex my-auto z-20'>
            <Button label='Get Started' variant={ButtonVariants.PRIMARY} onClick={() => navigate('/contact_us')}/>
          </div>

        </div>
        {/* md screen, sm screen */}
        <div 
          className={`${styles['navbar__nav-item-login-mobile']}`}
          onClick={handleMobileMenu}
          aria-hidden="true"
        >
          <div className={`${styles.navbar__icon}`}>
            <IconComponent.Bars width={32} height={18} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
