import { FC, useLayoutEffect, useState } from 'react'
import { motion } from "framer-motion"
import DesktopDropdownItem from '../DesktopDropdownItem/DesktopDropdownItem'
import DesktopDropdownProps from './DesktopDropdown.interface'
import styles from './DesktopDropdown.module.scss'

const DesktopDropdown: FC<DesktopDropdownProps> = ({title, dropdownItems}) => {
  const [isOverButton, setIsOverButton] = useState(false)
  const [isOverDropdown, setIsOverDropdown] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [rotate, setRotate] = useState(0)

  useLayoutEffect(() => {
      if ( isOverButton || isOverDropdown) {
          setIsOpen(true)
          setRotate(180)
      } else {
          setIsOpen(false)
          setRotate(0)
      }
  }, [isOverButton, isOverDropdown])

  const handleClick = () => {
      setIsOpen(false)
      setIsOverButton(false)
      setIsOverDropdown(false)
  }

  return (
    <>
      <div 
        className={`${styles['dropdown-nav-item']}`}
        onMouseOver={() => setIsOverButton(true)}
        onMouseOut={() => setIsOverButton(false)}
        onFocus={() => setIsOverButton(true)}
        onBlur={() => setIsOverButton(false)}
      >
        <p>{title}</p>
        <motion.svg 
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-8 h-8 my-auto"
          animate={{ rotate }}
        >

          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </motion.svg>

      </div>
      {isOpen && (
        <motion.div 
          className={`${styles.dropdown}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          onMouseOver={() => setIsOverDropdown(true)}
          onMouseOut={() => setIsOverDropdown(false)}
        >
          <div className={`${styles.dropdown__items}`}>
            {dropdownItems.map((item) => <DesktopDropdownItem title={item.title} subtitle={item.subtitle} link={`/solutions/${item.link}`} key={item.link} handleClick={handleClick}/>)}
          </div>
        </motion.div>
      )}
  </>
  )
}

export default DesktopDropdown
